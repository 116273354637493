import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I've recently dropped markdownRemark and started using MDX to write my posts. MDX is great since it allows you to import components and even write code that will be converted as expected. You can also use graphql queries on your posts which is very useful.`}</p>
    <p>{`I have been using the `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.com/plugins/gatsby-remark-prismjs/?=prism"
      }}>{`gatsby prism plugin`}</a>{` to get my code highlighting work, but after moving to MDX this plugin stopped working, so I went ahead in search for a way to get the code highlighting working again. `}<a parentName="p" {...{
        "href": "https://codetrain.io/adding-prism-syntax-highlighting-to-gatsby-mdx"
      }}>{`This article`}</a>{` did that.`}</p>
    <h2 {...{
      "id": "dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#dependencies",
        "aria-label": "dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Dependencies`}</h2>
    <p>{`To get the code highlighting work with MDX, we need to install the `}<a parentName="p" {...{
        "href": "https://github.com/FormidableLabs/prism-react-renderer"
      }}>{`pristm react renderer`}</a>{` package.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npm install prism-react-renderer
`}</code></pre>
    <h2 {...{
      "id": "the-code-block-component",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-code-block-component",
        "aria-label": "the code block component permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Code Block Component`}</h2>
    <p>{`Following the tutorial, I wrote a code block component. I've done some changes to my code block component since I wanted to use my code highlighting theme, I've also added line numbers to each line.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import React from "react"
import Highlight, { defaultProps } from "prism-react-renderer"

export default (props) => {

    const className = props.children.props.className || 'language-text' //needed for styling\`\`

    const matches = className.match(/language-(?<lang>.*)/)

    return (
        <div className="gatsby-highlight">
            <Highlight {...defaultProps} 
                code={props.children.props.children.trim()} 
                language={matches && matches.groups 
                        && matches.groups.lang 
                        ? matches.groups.lang : ''}
                theme=''
            >
            {({ className, tokens, getLineProps, getTokenProps}) => (
                <pre className={className}>
                    <code className={className}>
                    {tokens.map((line, i) => (
                        <div className="code-block" key={i} {...getLineProps({line, key:i})}>
                            <span className="line-number">{i + 1}</span>
                            {line.map((token, key) => (
                                <span key={key} {...getTokenProps({token, key})} />
                            ))}
                        </div>
                    ))}
                    </code>
                </pre>
            )}
            </Highlight>
        </div>
    )
}

`}</code></pre>
    <p>{`Notice that I am not passing a theme since I am using my own code highlight theme - you could download one theme from the `}<a parentName="p" {...{
        "href": "https://github.com/PrismJS/prism-themes"
      }}>{`official PrismJS repository`}</a>{` and use it instead.`}</p>
    <p>{`Or if you want, you can just copy `}<a parentName="p" {...{
        "href": "https://github.com/FabioRosado/fabiorosado.dev/blob/master/src/styles/_syntax-highlighting.scss"
      }}>{`my theme`}</a>{` and use it.`}</p>
    <h2 {...{
      "id": "expanding-the-code-block",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#expanding-the-code-block",
        "aria-label": "expanding the code block permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Expanding the code block`}</h2>
    <p>{`I've seen `}<a parentName="p" {...{
        "href": "https://www.christopherbiscardi.com/"
      }}>{`Chris Biscardi`}</a>{` digital garden and really liked the "header" of his code blocks, so I thought that it could be a nice addition to my code block.`}</p>
    <p>{`To allow users to copy the code inside a code block, I had to create a function for the copy button and handle the event when someone clicks on it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import React, { useState } from "react"
import Highlight, { defaultProps } from "prism-react-renderer"


export default (props) => {
    const className = props.children.props.className || 'language-text'

    const language = className.replace("language-", "")
    const matches = className.match(/language-(?<lang>.*)/)

    const CopyButton = props => {
        const [text, setText] = useState("Copy")

        return (
            <button className="code-copy-button" onClick={() => {
                navigator.clipboard.writeText(props.content)
                setText("Copied!")
                setTimeout(() => { setText("Copy")}, 1000)
            }}
            >
                {text}
            </button>
        )
    }

    return (
        <div className="gatsby-highlight">
            <Highlight {...defaultProps} 
                code={props.children.props.children.trim()} 
                language={matches && matches.groups && matches.groups.lang ? matches.groups.lang : ''}
                theme=''
            >
            {({ className, tokens, getLineProps, getTokenProps}) => (
                <pre className={className}>
                    <div className="code-header">
                        <span className="language-name">{language}</span>
                        <CopyButton content={props.children.props.children} />
                    </div>
                    <code className={className}>
                    {tokens.map((line, i) => (
                        <div className="code-block" key={i} {...getLineProps({line, key:i})}>
                            <span className="line-number">{i + 1}</span>
                            {line.map((token, key) => (
                                <span key={key} {...getTokenProps({token, key})} />
                            ))}
                        </div>
                    ))}
                    </code>
                </pre>
            )}
            </Highlight>
        </div>
    )
}
`}</code></pre>
    <h2 {...{
      "id": "adding-wrapper-component-to-mdxrenderer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adding-wrapper-component-to-mdxrenderer",
        "aria-label": "adding wrapper component to mdxrenderer permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding Wrapper Component to MDXRenderer`}</h2>
    <p>{`The step 3 of the `}<a parentName="p" {...{
        "href": "https://codetrain.io/adding-prism-syntax-highlighting-to-gatsby-mdx"
      }}>{`adding syntax highlighting to Gatsby Mdx with Prism`}</a>{`, says that we need to add the code block as a component to the `}<inlineCode parentName="p">{`MDXProvider`}</inlineCode>{` and also points to the `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/packages/gatsby-plugin-mdx/#mdxrenderer"
      }}>{`MDXRenderer documentation`}</a>{`.`}</p>
    <p>{`I have to admit that I couldn't understand fully how to do this. Everything that I tried was failing, luckily `}<a parentName="p" {...{
        "href": "https://www.willharris.dev/"
      }}>{`Will Harris`}</a>{` was kind enough to share his repository with me and explained that I should add the component to the `}<inlineCode parentName="p">{`gatsby-browser.js`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import React from "react"
import { MDXProvider } from "@mdx-js/react"

import CodeBlock from "./src/components/highlight/CodeBlock"

const component = {
    pre: CodeBlock
}

export const wrapRootElement = ({ element }) => {
    return <MDXProvider components={component}>{element}</MDXProvider>
}
`}</code></pre>
    <p>{`By adding this bit of code to the `}<inlineCode parentName="p">{`gatsby-browser.js`}</inlineCode>{` made the whole code highlighting work. `}</p>
    <p>{`If you chose a theme, you should have everything working, if you prefer to build your own, then you can keep on reading and use my theme.`}</p>
    <h2 {...{
      "id": "code-highlighting-theme",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#code-highlighting-theme",
        "aria-label": "code highlighting theme permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Code Highlighting Theme`}</h2>
    <p>{`I am using `}<inlineCode parentName="p">{`scss`}</inlineCode>{` on this website so the theme that I am going to share with you, will be in scss.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.gatsby-highlight {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.code-header {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #6089bd;
    padding: 1rem;
}

.language-name {
    padding-right: 1em;
    color: #ff85cb;
}

.code-copy-button {
    color: #79b3fd;
    background: none;
    border: none;
    cursor: pointer;
}

code {
  padding: 2px 6px;
  color: #78b2fd;
  border: 1px solid #1b2530;
  background-color: #1b2530;
  border-radius: 5px;
}


code[class*="language-"],
pre[class*="language-"] {
    padding: 0;
    color: #f8f8f2;
    background: none;
    text-shadow: 0 1px rgba(0, 0, 0, 0.3);
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    font-size: large;
}

/* Code blocks */
pre[class*="language-"] {
    overflow: auto;
    border-radius: 0.3em;
    border: 1px solid rgb(97, 137, 189);
    scrollbar-width: none;

    &::-webkit-scrollbar { 
        display: none; /* Chrome Safari */
    }
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
    background: #1A2430;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
    padding: .1em;
    border-radius: .3em;
    white-space: normal;
}

.line-number {
    color: #6272a4;
    padding-right: 2rem;
}

.token-line {
    padding: 0 1em;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
    color: #6272a4;
}

.token.punctuation {
    color: #f8f8f2;
}

.namespace {
    opacity: .7;
}

.token.property,
.token.tag,
.token.constant,
.token.symbol,
.token.deleted {
    color: #ff79c6;
}

.token.boolean,
.token.number {
    color: #bd93f9;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
    color: #50fa7b;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
    color: #f8f8f2;
}

.token.atrule,
.token.attr-value,
.token.function,
.token.class-name {
    color: #f1fa8c;
}

.token.keyword {
    color: #8be9fd;
}

.token.regex,
.token.important {
    color: #ffb86c;
}

.token.important,
.token.bold {
    font-weight: bold;
}

.token.italic {
    font-style: italic;
}

.token.entity {
    cursor: help;
}
`}</code></pre>
    <h2 {...{
      "id": "share-your-theme",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#share-your-theme",
        "aria-label": "share your theme permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Share your theme!`}</h2>
    <p>{`If you have used my template to get your own code highlighting and have changed it, I would love to see what you have created! `}</p>
    <p>{`You can add your own theme to my `}<a parentName="p" {...{
        "href": "https://github.com/FabioRosado/code-highlighting-theme"
      }}>{`code highlighting theme repository`}</a>{`.`}</p>
    <hr></hr>
    <p><strong parentName="p">{`References:`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://codetrain.io/adding-prism-syntax-highlighting-to-gatsby-mdx"
        }}>{`Adding Syntax Highlighting to Gatsby MDX with Prism`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.christopherbiscardi.com/"
        }}>{`Chris Biscardi repository - for the language and copy button`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.willharris.dev/"
        }}>{`Will Harris - for sharing his repo with me`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      